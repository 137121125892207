* {
    margin: 0;
    padding: 0;
    font-family: 'Bebas Neue', cursive;
}

/* -------------------- Nav Bar Customize ------------------------------------------- */
.navbar-space {
    margin-top: 0vh;
}

nav.nav-bar {
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 90px;
    background-color: #F1FBF8;
    display: flex;

}

nav.nav-bar a:first-child {
    margin-right: auto;
}

nav.nav-bar a:last-child {
    margin-left: auto;
    width: 5%;
}

nav.nav-bar a {
    margin: 0px 20px;
    padding: 10px 30px;
    text-decoration: none;
    color: black;
}

nav.nav-bar a:hover {
    background-color: #5AC1A9;
    color: white;
}

nav.nav-bar a:hover:last-child {
    background-color: transparent;
    color: transparent;
}

nav.nav-bar a:hover:first-child {
    background-color: transparent;
    color: transparent;
}

.contact-circle {
    background: linear-gradient(180deg, #5F7EBD 0%, #5FBDA7 0.01%, #009874 100%);
    width: 50px;
    height: 50px;
    margin-top: 15px;
    align-items: center;
    border-radius: 50%;
    color: #000000;
    transition: transform .5s ease;
}

.contact-circle:hover {
    transform: scale(1.5);
}

/* -------------------- Bottom Bar Customize ------------------------------------------- */
.bottom-bar-style {
    background: url(./image/bottombarimage.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 100%;
}

.bottom-bar {
    width: 100%;
    min-height: 100px;
    padding-top: 20px;
    padding-bottom: 20px;
    display: flex;
    flex-direction: row;
}

.bottom-bar-con-1 {
    width: 37%;
    padding-left: 40px;
}

.bottom-bar-con-2 {
    width: 19%;
}

.bottom-bar-con-3 {
    width: 19%;
    padding-left: 40px;
}

.bottom-bar-con-3 a {
    text-decoration: none;
}

.bottom-bar-con-3 a:hover {
    text-decoration: underline white 2px;
}

.bottom-bar-con-4 {
    width: 25%;
}

.bottom-bar-social-icon {
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    padding: 2px 2px;
}

.bottom-bar-social-box {
    width: 40px;
    margin: 2px 10px;
    border-radius: 10px;
    padding: 2px 5px;
}

.bottom-bar-logo {
    margin-top: 10px;
    padding: 2px 20px;
}

.bottom-bar-topic {
    color: rgb(255, 255, 255);
    font-weight: 500;
    font-size: 25px;
    margin-bottom: 10px;
    line-height: 40px;
}

.bottom-bar-data {
    color: rgba(184, 173, 173, 1);
    font-size: 18px;
    margin-bottom: 10px;
}

.bottom-bar-image {
    border: rgba(184, 173, 173, 1) solid 1px;
    min-width: 300px;
    max-width: 300px;
    max-height: 200px;
    min-height: 200px;
    margin: auto;
    position: relative;
}

.bottom-bar-image p {
    position: absolute;
    bottom: 8px;
    color: rgba(184, 173, 173, 1);
    left: 16px;
}

.bottom-bar-div {
    border-top: 1px rgba(255, 255, 255, 1) solid;
    width: 90%;
    min-height: 50px;
    margin: auto;
}

.bottom-bar-div p {
    color: rgba(184, 173, 173, 1);
    padding: 10px 10px;
}

/* -------------------- Whatsapp link Customize ------------------------------------------- */
.fixed-whatsapp-contact {
    position: fixed;
    bottom: 30%;
    right: 0;
    width: 80px;
    padding: 10px;
    padding-left: 30px;
    background: rgba(181, 178, 178, 0.49);
    backdrop-filter: blur(25px);
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
    z-index: 1;
    transition: transform .5s ease;
}

.fixed-whatsapp-contact:hover {
    transform: scaleY(1.2);
}

.fixed-whatsapp-contact a {
    color: black;
}

/* .fixed-whatsapp-contact a:hover {    
    transform: scale(1.5);
} */

/* -------------------- Landing Page Customize ------------------------------------------- */
.landing-page-box {
    width: 90%;
    margin: auto;
}

.landing-page-data-box {
    display: flex;
    flex-direction: row;
}

.landing-page-data-box div {
    width: 50%;
}

.landing-page-data {
    /* font-family: sans-serif; */
    width: 50%;
    margin: auto;
    margin-top: 20px;
    text-align: center;
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 28px;
    line-height: 50px;
    color: #000000;
}

#topic {
    font-size: 70px;
    font-weight: 800;
    font-family: 'Bebas Neue', sans-serif;
    margin-top: 50px;
    line-height: 80px;
    margin-bottom: 50px;
}

.landing-page-data-box #landing-image {
    width: 100%;
    height: auto;
    margin: 2% 0%;
}

.landing-page-box-mainbar {
    position: relative;
    max-width: 250px;
    height: 3px;
    left: 60px;
    top: 0px;
    background: #DADADA;
    border-radius: 60px;
}

.landing-page-box-secondbar {
    position: relative;
    min-width: 150px;
    height: 2px;
    left: 0px;
    top: 0px;
    background: #1F987B;
    border-radius: 60px;
}

.landing-page-box-font {
    font-weight: 400;
    font-size: 35px;
    line-height: 42px;
    margin-top: 10%;
    margin-left: 10%;
    /* identical to box height */
    letter-spacing: 0.005em;
    color: #595656;
}

.landing-page-box-font-topic {
    font-weight: 400;
    font-size: 80px;
    line-height: 42px;
    margin-top: 50px;
    margin-left: 10%;
    /* identical to box height */
    letter-spacing: 0.005em;
    color: #595656;
}

.landing-page-box-button {
    margin-left: 10%;
    margin-top: 50px;
}

.landing-page-box-button button {
    width: 300px;
    height: 70px;
    background-color: #1F987B;
    border: transparent;
    border-radius: 50px;
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 44px;
    /* identical to box height */
    color: #FFFFFF;
    transition: transform .8s ease;
}

.landing-page-box-button button:hover {
    transform: scale(1.3);
}

.landing-page-logo-data {
    width: 80%;
    margin: auto;
    display: block;
}

.landing-page-logo-data img {
    width: 20%;
    margin: 10px 2.5%;
}

.landing-page-bg-image {
    margin-top: 30px;
    width: 100%;
}

.landing-page-bg-image img {
    width: 100%;
}

.landing-page-bo04-data {
    width: 80%;
    margin: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.landing-page-bo04-data img {
    margin: 0% 20%;
    max-width: 60%;
}

.landing-page-bo04-data p {
    text-align: center;
    font-size: 30px;
    text-transform: capitalize;
}

#landing-page-bo04-text {
    color: #1F987B;
}

.landing-box05 {
    display: flex;
    margin: auto;
    width: 94%;
    flex-direction: row;
    justify-content: space-around;
}

.landing-box05 div {
    /* border: red solid 1px; */
    width: 100%;
}

.landing-box05-inline-text-box {
    display: flex;
    min-width: 90%;
    margin-top: 30px;
    margin-left: 5%;
    flex-direction: row;
    justify-content: space-around;
}

.landing-box05-inline-text-box input {
    width: 90%;
    margin: auto 5%;
    height: 30px;
    border: transparent;
}

.landing-box05-inline-email-box {
    margin-left: 5%;
    margin-top: 50px;
}

.landing-box05-inline-email-box input {
    width: 90%;
    margin: auto 5%;
    height: 30px;
    border: transparent;
}

.landing-box05-inline-email-box textarea {
    width: 90%;
    margin: 2% 5%;
    height: 80px;
    border: transparent;
}

.text-box-main-border {
    width: 80%;
    height: 80px;
    margin: auto;
}

.text-box-top-border {
    max-width: 80%;
    height: 20px;
    margin: auto;
    border-left: #625C5C solid 1px;
    border-right: #625C5C solid 1px;
}

.text-box-bottom-border {
    max-width: 80%;
    height: 20px;
    margin: auto;
    border-left: #625C5C solid 1px;
    border-right: #625C5C solid 1px;
}

.text-box-ceter-border {
    max-width: 90%;
    height: 40px;
    margin: auto;
    border-top: #625C5C solid 1px;
    border-bottom: #625C5C solid 1px;
}

.form-box-contact input {
    font-family: 'Open Sans';
    width: 90%;
    color: black;
    font-size: 20px;
    background: none;
}

.form-box-contact textarea {
    font-family: 'Open Sans';
    width: 90%;
    color: black;
    font-size: 20px;
    background: none;
}

.form-box-contact input:active {
    background: none;
}

.text-box-lr-border {
    max-width: 89%;
    height: 60px;
    margin: auto;
    border-left: #625C5C solid 1px;
    border-right: #625C5C solid 1px;
}


.text-area-text-box-main-border {
    width: 80%;
    height: 140px;
    margin: auto;
}

.text-area-text-box-top-border {
    max-width: 80%;
    height: 20px;
    margin: auto;
    border-left: #625C5C solid 1px;
    border-right: #625C5C solid 1px;
}

.text-area-text-box-bottom-border {
    max-width: 80%;
    height: 20px;
    margin: auto;
    border-left: #625C5C solid 1px;
    border-right: #625C5C solid 1px;
}

.text-area-text-box-ceter-border {
    max-width: 90%;
    height: 100px;
    margin: auto;
    border-top: #625C5C solid 1px;
    border-bottom: #625C5C solid 1px;
}

.text-area-text-box-lr-border {
    max-width: 89%;
    height: 100px;
    margin: auto;
    border-left: #625C5C solid 1px;
    border-right: #625C5C solid 1px;
}

.landing-box05-button-div {
    margin: auto;
    margin-top: 30px;
    margin-bottom: 30px;
    height: 40px;
}

.landing-box05-button-div button {
    min-width: 40%;
    max-width: 40%;
    font-size: 30PX;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    color: wheat;
    border: none;
    margin-left: 33%;
    height: 40px;
    background: linear-gradient(180deg, #5AC1A9 0%, #009874 100%);
    border-radius: 10px;
}

.landing-box05-button-div button:hover {
    box-shadow: 0 4px 8px 0 #5AC1A9, 0 6px 20px 0 #009874;
}

.landing-box05-data-box-cs {
    padding: 0% 5%;
}

.landing-box05-data-box-cs #topic {
    font-size: 40px;
    padding-bottom: 0px;
    margin-bottom: 0px;
}

.landing-box05-data-box-cs #para {
    padding: 3% 0;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 25px;
    line-height: 40px;
    /* or 160% */
    color: #6A6767;
}

.landing-box05-data-box-cs #data {
    padding: 3% 0;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 23px;
    line-height: 40px;
    /* or 174% */
    letter-spacing: 0.005em;

    color: #007358;
}

.landing-page-box5-mainbar {
    position: relative;
    max-width: 250px;
    height: 4px;
    left: 0px;
    top: 0px;
    background: #DADADA;
    border-radius: 60px;
}

.landing-page-box5-secondbar {
    position: relative;
    max-width: 160px;
    height: 3px;
    left: 0px;
    top: 0px;
    background: #1F987B;
    border-radius: 60px;
}

.landing-box-img-gallry {
    width: 80%;
    margin: 20px auto;
    border: red solid 1px;
}

.landing-box-img-gallry img {
    display: inline-block;
    max-width: 20%;
    margin: 10px;
    height: auto;


}

/* -------------------- Sector Page Customize ------------------------------------------- */

.sector-page-image-bar {
    display: inline-block;
}

.sector-page-image-bar img {
    width: 300px;
    margin: 5px 20px;
    border-radius: 20px;
}

/* -------------------- About Us Page Customize ------------------------------------------- */

.about-us-image-box-01 {
    position: relative;
    width: 100%;
    max-height: 580px;
}

.about-us-image-box-01 img {
    width: 80%;
    max-height: 580px;
    border-bottom-right-radius: 100px;
}

.about-us-image-box-01 p {
    position: absolute;
    bottom: 20px;
    width: 60%;
    text-align: right;
    right: 8%;
    font-style: normal;
    font-weight: 400;
    font-size: 180px;
    line-height: 100px;
    /* identical to box height, or 62% */
    letter-spacing: 0.005em;
    color: #ffffff;
    filter: drop-shadow(0px 10px 4px #363333);
}

.def-color {
    color: #000000;
}

.about-us-image-box-02 {
    display: flex;
    flex-direction: row;
}

.about-us-image-box-02 .box-02-p1 {
    min-width: 45%;
    margin-top: 40px;
    padding: 30px;
}

.about-us-image-box-02 .box-02-p5 {
    display: none;
}

.about-us-image-box-02 .box-02-p1 p {
    text-align: center;
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 25px;
    line-height: 40px;
    color: #000000;

}

.about-us-image-box-02 .box-02-p2 {
    max-width: 55%;
    border-top-left-radius: 100px;
    border-bottom-left-radius: 100px;
    filter: drop-shadow(8px 8px 10px gray);
}

.about-us-main-div-box02 {
    min-height: 400px;
    margin: auto;
}

.about-us-mobile-view-text {
    display: none;
}

/* ----------------- Border Custom ----------------- */
.about-us-box-main-border {
    width: 81%;
    height: 500px;
    margin: auto;
}

.about-us-box-top-border {
    max-width: 81%;
    height: 20px;
    margin: auto;
    border-left: #625C5C solid 1px;
    border-right: #625C5C solid 1px;
}

.about-us-box-ceter-border {
    max-width: 90%;
    height: 270px;
    margin: auto;
    border-top: #625C5C solid 1px;
    border-bottom: #625C5C solid 1px;
}

.about-us-box-lr-border {
    max-width: 90%;
    height: 290px;
    margin: auto;
    border-left: #625C5C solid 1px;
    border-right: #625C5C solid 1px;
}

.about-us-box-lr-border p {
    padding: 0 20px;
    padding-top: 80px;
    text-align: center;
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 25px;
    line-height: 40px;
    color: #000000;
}

.about-us-box-mainbar {
    position: relative;
    min-width: 50px;
    max-width: 350px;
    height: 3px;
    left: 550px;
    top: 0px;
    background: #A79898;
    border-radius: 60px;
}

.about-us-box-secondbar {
    position: relative;
    max-width: 250px;
    height: 2px;
    left: 0px;
    top: 0px;
    background: #020202;
    border-radius: 60px;
}

.about-us-box-main-border img {
    width: 100px;
    height: 100px;
    position: relative;
    left: 45%;
    top: 60px;
}

/* ------------------------------------------- */
.image-test-data {
    width: 20%;
}

input:focus,
textarea:focus,
select:focus {
    outline: none;
}



/* -------------------- Feature Brand Page Customize ------------------------------------------- */
#pc-no-logo {
    display: none;
}

.featuredbrandbox1 {
    width: 100%;
    margin: auto;
}

.featuredbrandboxdiv {
    position: relative;
    width: 100%;
    height: 530px;
    background-color: #F1FBF8;
}

.featuredbrandboxdiv .box {
    position: absolute;
    width: 40%;
    height: 450px;
    left: 55%;
    top: 50px;
    border: 3px solid #000000;
}

.featuredbrandboxdiv .box-image {
    position: absolute;
    width: 40%;
    height: 450px;
    left: 53%;
    top: 70px;
}

.featuredbrandboxdiv .box-text01 {
    position: absolute;
    left: 10%;
    top: 200px;
    width: 200px;
    height: 100px;
}

.featuredbrandboxdiv .box-text02 {
    position: absolute;
    left: 31%;
    top: 350px;
    width: 200px;
    height: 100px;
}

.featuredbrandbox1 h5 {
    font-family: 'Bebas Neue';
    font-style: normal;
    font-weight: 400;
    font-size: 140px;
    line-height: 100px;
    letter-spacing: 0.005em;
    color: #000000;
}

.featuredbrandbox1 .box-text03 {
    text-align: center;
    width: 80%;
    margin: auto;
    left: 10%;
    top: 650px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 300;
    font-size: 40px;
    line-height: 65px;
    text-align: center;
    letter-spacing: 0.005em;

    color: #000000;
}

.featuredBrandBox02 {
    width: 80vw;
    margin: 80px auto;
}

.featuredBrandBox02 img {
    width: 170px;
    height: 150px;
    padding: 30px;
    margin: auto 6%;
    border-left: 2px solid #8c8b8b;
    border-right: 2px solid #8c8b8b;
    transition: transform .5s ease;
}

.featuredBrandBox02 img:hover {
    transform: scale(1.2);
    animation-name: imagehour;
    animation-duration: 5s;
    animation-iteration-count: 3;
}

#fb02-special-hr {
    display: none;
}

#special-div {
    margin: 20px 25%;
}

@keyframes imagehour {
    0% {
        border-left: 2px solid #8c8b8b;
        border-right: 2px solid #8c8b8b;
    }

    25% {
        border-left: 2px solid #ff00008c;
        border-right: 2px solid #ff00008c;
    }

    50% {
        border-left: 2px solid #ff0000;
        border-right: 2px solid #ff0000;
    }

    75% {
        border: 2px solid #ff0000;
    }

    100% {
        border: 4px solid #ff0000;
    }
}

.featuredBrandBox03 {
    width: 80%;
    margin: 20px auto;
}

.featuredBrandBox03 h4 {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 300;
    font-size: 60px;
    line-height: 100px;
    /* or 167% */

    text-align: center;
    letter-spacing: 0.005em;

    color: #00B88D;
}

.featuredBrandBox03 p {
    font-family: 'Open Sans';
    font-style: normal;
    padding: 20px 20px;
    font-weight: 300;
    font-size: 30px;
    line-height: 50px;
    /* or 162% */

    text-align: center;
    letter-spacing: 0.005em;

    color: #000000;
}

.featuredBrandBox04 {
    width: 80%;
    margin: 20px auto;
}

.featuredBrandBox04 p {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 300;
    font-size: 50px;
    line-height: 100px;
    text-align: center;
    letter-spacing: 0.005em;
    color: #00B88D;
}

/* -------------------- News Page Customize ------------------------------------------- */
.newspagediv {
    width: 100%;
    margin: auto;
    margin-bottom: 50px;
}

.newspagediv h4 {
    font-family: 'Bebas Neue';
    padding: 100px 0px;
    padding-left: 30%;
    font-style: normal;
    font-weight: 400;
    font-size: 140px;
    line-height: 100px;
    background: #F1FBF8;
    letter-spacing: 0.005em;
    color: #000000;
}

.newspagediv h5 {
    margin-top: 25px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 300;
    font-size: 55px;
    line-height: 100px;
    /* identical to box height, or 182% */

    text-align: center;
    letter-spacing: 0.005em;

    color: #000000;
}

.newspagediv p {
    margin-top: 25px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 300;
    font-size: 40px;
    line-height: 65px;
    /* or 162% */

    text-align: center;
    letter-spacing: 0.005em;

    color: #000000;
}

.newspagediv .emailForm {
    position: relative;
}

.newspagediv .emailForm input {
    font-family: 'Open Sans';
    font-style: normal;
    font-size: 20px;
}

.newspagediv button {
    position: absolute;
    top: 16px;
    right: 100px;
    border-radius: 15px;
    background-image: url(./image/buttonmail.png);
    width: 50px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 50px;
    border: none;
    transition: transform .5s ease;
}

.newspagediv button:hover {
    transform: scale(1.5);
}

.contctUsPage {
    width: 100%;
    min-height: 250px;
    padding-top: 100px;
    padding-left: 6%;
    margin: 0 auto;
    background: #F1FBF8;
}

.contctUsPage h5 {
    font-family: 'Bebas Neue';
    font-style: normal;
    text-align: center;
    font-weight: 400;
    font-size: 140px;
    line-height: 100px;
    /* identical to box height, or 71% */
    letter-spacing: 0.005em;
    color: #000000;
}

.progress-bar {
    position: fixed;
    z-index: 2;
    top: 90px;
    left: 0;
    height: 10px;
    width: 100%;
    background-color: #05c79af3;
    transform-origin: 0%;
}

/* ---------------- Scroll top arrow --------------------- */

.scrollTop {
    position: fixed;
    background: url(./image/gotop.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border-radius: 50px;
    width: 50px;
    left: 50px;
    bottom: 20px;
    align-items: center;
    min-height: 50px;
    justify-content: center;
    z-index: 1;
    cursor: pointer;
    animation: fadeIn 0.3s;
    transition: opacity 0.4s;
    opacity: 0.7;
}

.scrollTop:hover {
    opacity: 1;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@media only screen and (max-width: 600px) {
    .fixed-nav-bar {
        display: none;
    }

    .landing-page-mobile {
        max-width: 100vw;
        min-width: 100vw;
        border: solid 1px #8c8b8b;
    }

    .bottom-bar-con-4 {
        display: none;
    }

    /* Mobile Menu Nav Bar */
    .show-mobile-menu-button {
        z-index: 1;
        position: fixed;
        padding-top: 2vh;
        top: 0px;
        left: 0px;
        width: 100vw;
        height: 14vh;
        display: flex;
        background: #F1FBF8;
    }

    .show-mobile-menu-button .mobile-logo {
        padding: 3vh 3vw;
        width: 20vw;
    }

    .show-mobile-menu-button .free-space {
        width: 46vw;
    }

    .show-mobile-menu-button .mobile-contact {
        margin-top: 3vh;
        margin-right: 5vw;
        width: 10vw;
        color: rgb(0, 0, 0);
        border-radius: 50px;
        background-color: #007358;
        height: 5vh;
    }

    .show-mobile-menu-button .mobile-navBtn {

        padding: 3vh 3vw;
        margin-right: 4vw;
        width: 5vw;
        font-size: 40px;
        border: none;
        background: transparent;
        cursor: pointer;
    }

    .close-mobile-menu-button {
        position: fixed;
        z-index: 999;
        top: 10px;
        right: 10px;
        width: 60px;
        height: 60px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        border: none;
        background: transparent;
        font-size: 80px;
        color: #fff;
        cursor: pointer;
    }

    .mobile-menu-bar {
        background-color: #007358;
    }

    /* Mobile Menu */
    .mobile-menu {
        position: fixed;
        z-index: 99;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: rgba(0, 0, 0, 0.85);
        transition: 0.3s;
    }

    .mobile-menu a {
        font-family: 'Open Sans';
        padding: 10px 0px;
        color: rgba(255, 255, 255, 0.7);
        font-weight: 800;
        font-size: 35px;
        text-decoration: none;
    }

    .mobile-menu a:hover {
        color: orange;
    }

    /* Bottom Bar Customize */
    .bottom-bar-con-1 {
        width: 30%;
        border-right: 1px rgba(199, 199, 193, 0.815) solid;
        padding-left: 0px;
    }

    .bottom-bar-con-2 {
        padding-left: 20px;
        width: 40%;
        border-right: 1px rgba(199, 199, 193, 0.815) solid;
    }

    .bottom-bar-con-3 {
        width: 20%;
        padding-left: 20px;
    }

    .bottom-bar-con-4 {
        display: none;
    }

    .bottom-bar-social-icon {
        flex-direction: column;
        align-content: space-around;
        padding: 0px 30%;
    }

    .bottom-bar-social-box {
        margin: 10px 0px;
    }

    .bottom-bar-logo {
        margin: 10px auto;
        width: 95%;
        padding: auto;
    }

    .navbar-space {
        margin-top: 14vh;
    }

    /* Landing Page Customize for mobile */
    .hr-space,
    .progress-bar {
        display: none;
    }

    .landing-page-box {
        /* border: solid rgb(255, 255, 255) 1px; */
    }

    .landing-page-box-font {
        font-style: normal;
        font-size: 13px;
        line-height: 16px;
        letter-spacing: 0.005em;
    }

    .landing-page-box-mainbar {
        max-width: 49px;
        min-width: 49px;
        height: 2px;
        left: 18px;
        top: 0px;
    }

    .landing-page-box-secondbar {
        max-width: 34.84px;
        min-width: 34.84px;
        height: 2px;
    }

    .landing-page-box-font-topic {
        font-weight: 400;
        font-size: 35px;
        margin-top: 10px;
        margin-left: 10%;
        letter-spacing: 0.005em;
    }

    .landing-page-data-box #landing-image {
        width: 100%;
        height: auto;
        margin: 6vh 0%;
    }

    .landing-page-box-button {
        margin-left: 24px;
        margin-top: 10px;
    }

    .landing-page-box-button button {
        width: 138px;
        height: 38px;
        font-size: 15px;
        line-height: 20px;
        transition: transform .8s ease;
    }

    .landing-page-box-button button:hover {
        transform: scale(1.2);
    }

    .landing-page-data {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 300;
        font-size: 20px;
        line-height: 35px;
        text-align: center;
        width: 80%;
    }

    .mobile-view-br {
        display: none;
    }

    .landing-page-data {
        /* font-family: sans-serif; */
        width: 70%;
        margin: auto;
        margin-top: 20px;
        text-align: center;
        font-size: 20px;
        line-height: 30px;
        text-align: center;
        letter-spacing: 0.005em;

    }

    #topic {
        font-size: 40px;
        line-height: 35px;
        letter-spacing: 0.005em;
    }

    .landing-page-bo04-data div {
        border-left: solid rgba(65, 62, 62, 0.616) 2px;
        padding: 0 5px;
    }

    .landing-page-bo04-data div:first-child {
        border-left: none;
    }

    .landing-page-bo04-data img {
        margin: 0% 15%;
        max-width: 70%;
    }

    .landing-page-bo04-data p {
        text-align: center;
        font-size: 22px;
        text-transform: capitalize;
    }

    .landing-box05 {
        margin: 0 5px;
        width: 94%;
        flex-direction: column;
    }


    .landing-box05-data-box-cs #topic {
        font-weight: 400;
        font-size: 40px;
        line-height: 65px;
        letter-spacing: 0.005em;
    }

    .landing-box05-data-box-cs #para {
        padding: 20px 10px;
        font-weight: 400;
        font-size: 18px;
        line-height: 25px;
        text-align: center;
    }

    .landing-box05-data-box-cs .data-bar {
        display: flex;
        justify-content: space-between;
    }

    .landing-box05-data-box-cs #data {
        padding: 10px 10px;
        font-family: 'Open Sans';
        font-size: 14px;
        line-height: 18px;
    }

    /* About Us Page Customize for mobile */

    .about-us-image-box-01 {
        width: 100vw;
        max-height: 30vh;
    }

    .about-us-image-box-01 img {
        width: 80vw;
        height: 30vh;
        max-height: 30vh;
        border-bottom-right-radius: 100px;
    }

    .about-us-image-box-01 p {
        width: 70vw;
        right: 3vw;
        font-size: 65px;
        line-height: 100px;
        letter-spacing: 0.005em
    }

    .about-us-image-box-02 {
        margin-top: 0px;
        display: flex;
        flex-direction: row;
    }

    .about-us-image-box-02 .box-02-p1 {
        display: none;
    }

    .about-us-image-box-02 .box-02-p5 {
        display: contents;
        min-width: 45vw;
    }

    .about-us-image-box-02 .box-02-p5 p {
        margin-top: 0px;
        text-align: left;
        font-family: 'Open Sans', sans-serif;
        padding: 25px 15px;
        font-size: 12px;
        line-height: 25px;
        letter-spacing: 0.005em;
        color: #000000;

    }

    .about-us-image-box-02 .box-02-p2 {
        max-width: 55vw;
        border-top-left-radius: 30px;
        border-bottom-left-radius: 30px;
    }

    .about-us-mobile-view-text {
        display: contents;
    }

    .about-us-mobile-view-text p {
        width: 80vw;
        margin: auto;
        margin-top: 10vh;
        text-align: center;
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 300;
        font-size: 14px;
        line-height: 25px;
    }

    .about-us-box-mainbar {
        left: 32vw;
        min-width: 40vw;
        max-width: 40vw;
    }

    .about-us-box-secondbar {
        max-width: 30vw;
    }

    .about-us-box-main-border {
        width: 81%;
        max-height: 45vh;
        margin: auto;
    }

    .about-us-box-main-border img {
        width: 80px;
        height: 80px;
        position: relative;
        left: 30vw;
        top: 10vh;
    }

    .about-us-box-ceter-border {
        height: 35vh;
    }

    .about-us-box-lr-border {
        height: 38vh;
    }

    .about-us-box-lr-border p {
        padding: 0 20px;
        padding-top: 8vh;
        font-size: 14px;
        line-height: 28px;
        letter-spacing: 0.010em;
    }

    /* Feature Brand Page Customize for mobile */
    #mobile-no-logo {
        display: none;
    }

    #pc-no-logo {
        display: contents;

    }

    #pc-no-logo .contain {
        position: relative;
        width: 100vw;
    }

    #pc-no-logo .contain img {
        margin: 5vh 11vw;
        min-width: 25vw;
        max-width: 25vw;
    }

    .vl {
        border-left: 1px solid rgba(0, 0, 0, 0.236);
        height: 73.5vh;
        position: absolute;
        left: 50%;
        top: 0;
    }

    .featuredBrandBox02 #fb02-special-hr-no {
        display: none;
    }

    .featuredbrandbox1 {
        width: 100vw;
    }

    .featuredbrandboxdiv {
        width: 100vw;
        height: 60vh;
    }

    .featuredbrandboxdiv .box {
        width: 65vw;
        max-height: 50vh;
        left: 30vw;
        top: 5vh;
    }

    .featuredbrandboxdiv .box-image {
        width: 65vw;
        max-height: 50vh;
        left: 25vw;
        top: 7vh;
    }

    .featuredbrandboxdiv .box-text01 {
        left: 3vw;
        top: 33vh;
        width: 40vw;
        height: 10vh;
    }

    .featuredbrandboxdiv .box-text02 {
        position: absolute;
        left: 15vw;
        top: 43vh;
        width: 40vw;
        height: 10vh;
    }

    .featuredbrandbox1 h5 {
        font-size: 70px;
    }

    .featuredbrandbox1 .box-text03 {
        width: 80vw;
        margin: 5vh auto;
        /* top: 10vh;
        left: 10vw; */
        font-size: 17px;
        line-height: 30px;
    }

    .featuredBrandBox03 {
        width: 80vw;
        margin: 10px auto;
    }

    .featuredBrandBox03 h4 {
        font-size: 20px;
        line-height: 30px;
        margin: 20px auto;
    }

    .featuredBrandBox03 p {
        padding: 2px 10px;
        font-size: 15px;
        line-height: 25px;
    }

    .featuredBrandBox04 {
        width: 80vw;
    }

    .featuredBrandBox04 p {
        font-size: 25px;
        line-height: 10px;
    }

    /* New Page Customize */
    .newspagediv {
        width: 100vw;
        margin-bottom: 50px;
    }

    .newspagediv h4 {
        min-height: 30vh;
        padding: 0px 0px;
        padding-left: 10vw;
        padding-top: 16vh;
        font-size: 60px;
        line-height: 0px;
    }

    .newspagediv h5 {
        font-size: 30px;
        line-height: 35px;
    }

    .newspagediv p {
        margin: 15px;
        margin-top: 25px;
        padding: 10px 10px;
        font-size: 17px;
        line-height: 25px;
    }

    .newspagediv button {
        top: 1.8vh;
        right: 3vw;
    }

    .contctUsPage {
        width: 100vw;
        min-height: 30vh;
        max-height: 30vh;
        padding-left: 0%;

    }

    .contctUsPage h5 {
        padding-left: 10vw;
        padding-top: 3vh;
        text-align: left;
        font-size: 60px;

    }

    .form-box-contact input,
    .form-box-contact textarea {
        font-size: 15px;
    }


}

/* Product View Page Customize*/
.popup-card-box {
    position: fixed;
    background: #00000050;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
}

.popup-card-box-space {
    width: 100vw;
    height: 100vh;
}

.popup-card-box .box {
    z-index: 0;
    position: relative;
    width: 70vw;
    max-height: 60vh;
    min-height: 60vh;
    margin-left: 15vw;
    border-radius: 10px;
    padding: 5vh 0vw;
    margin-top: 20vh;
    background: white;
    overflow: auto;

}

@media (min-width: 600px) {
    .popup-card-box .box {
        display: flex;
        justify-content: space-around;
    }
}

@media (max-width: 600px) {
    .popup-card-box .box {
        width: 80vw;
        max-height: 70vh;
        min-height: 70vh;
        margin: auto;
        padding: 0vh 0vw;
        margin-top: 20vh;
    }
}

.popup-card-box .box .img-data {
    width: 25vw;
    border-radius: 25px;
}

.popup-card-box .box .img-data img {
    min-width: 25vw;
    max-width: 25vw;
    max-height: 50vh;
    border-radius: 25px;
    height: fit-content;
    box-shadow: inset 0 -3em 3em rgba(0, 0, 0, 0.1), 0 0 0 2px rgb(255, 255, 255),
        0.3em 0.3em 1em rgba(0, 0, 0, 0.3);

}

@media (max-width: 600px) {
    .popup-card-box .box .img-data {
        min-width: 50vw;
        margin: auto;
        max-width: 50vw;
        max-height: 70vh;
    }

    .popup-card-box .box .img-data img {
        min-width: 50vw;
        max-width: 50vw;
        max-height: 50vh;
    }
}

.popup-card-box .box .sec-data {
    width: 25vw;
}

.popup-card-box .box .sec-data h5 {
    font-size: 1.8rem;
    font-weight: 500;
    text-align: center;
    text-decoration: underline;
    text-decoration-color: rgba(52, 42, 42, 0.632);
}

@media (max-width: 600px) {
    .popup-card-box .box .sec-data {
        width: 60vw;
        margin: auto;
        margin-top: 10px;
    }

    .popup-card-box .box .sec-data h5 {
        font-size: 1.5rem;
    }
}

#navigate-back::before {
    content: '<';
}

#navigate-back {
    position: absolute;
    top: 1px;
    left: 1px;
    float: right;
    background-color: #007358;
    color: white;
    font-size: 2.2rem;
    width: 40px;
    height: 40px;
    border-radius: 50px;
}

.description-text-data {
    margin-top: 10px;
    min-height: 30vh;
    max-height: 30vh;
    font-size: 20px;
    overflow-y: auto;
    margin-bottom: 10px;
}

.inquire-btn {
    padding: 5px;
    text-align: center;
    background-color: #007358;
    width: 15vw;
    height: 40px;
    font-size: 25px;
    margin: auto;
    color: white;
    border-radius: 10px;
    cursor: context-menu;
    box-shadow: inset 0 -3em 3em rgba(0, 0, 0, 0.1), 0 0 0 2px rgb(255, 255, 255),
        0.3em 0.3em 1em rgba(0, 0, 0, 0.3);
}

.inquire-btn:hover {
    border: solid 2px #007358;
    color: #000000;
    background-color: white;

}

@media (max-width: 600px) {
    .description-text-data {
        margin-top: 10px;
        min-height: 20vh;
        max-height: 30vh;
        font-size: 20px;
        overflow-y: auto;
    }

    .inquire-btn {
        width: 30vw;
        height: 40px;
        margin-bottom: 20px;
    }
}